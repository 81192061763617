import axios from 'axios';

const token = localStorage.getItem('anchorage_token');

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://34.125.108.55:4000/api/v1',
  timeout: 7199,
  headers: {
    'Authorization': 'Bearer ' + token,
  }
})

http.interceptors.request.use(config => {
  return config;
});

export default http;